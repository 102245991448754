import React, { useState } from "react";

import VerticalAlignTopOutlinedIcon from "@mui/icons-material/VerticalAlignTopOutlined";

import TextField from "@mui/material/TextField";
import { Divider, Tooltip } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Loader from "react-js-loader";
import Select from "react-select";
import { Modal } from "react-bootstrap";

import { FONT_STYLE, FONT_STYLE_SELECT } from "pages/Common/constants";
import { currencyFormatter } from "pages/Common/utils/currencyFormatter";
import UploadFilesModal from "pages/warrantyManagement/UploadFilesModal";

import {
  claimOrderRequestObj,
  claimRelatedHERequestObj,
  claimRequestObj,
  claimValueRequestObj,
  evaluationPartsRequestObj,
  evaluationRequestObj,
  questionsOptions,
} from "pages/warrantyManagement/warrantyManagementConstants";

import {
  createClaim,
  createClaimEvaluation,
  createClaimEvaluationPart,
  createClaimOrder,
  createClaimValue,
  createRelatedHRandExpenses,
  createRelatedParts,
  updateClaim,
  updateClaimEvaluation,
  updateClaimEvaluationParts,
  updateClaimOrder,
  updateClaimValue,
  updateRelatedHRandExpenses,
  updateRelatedParts,
} from "services/warrantyServices";

const dummyTableRecords = [
  {
    id: 1,
    partNumber: "ST60DCB",
    description: "Star 60 DC - DC Board",
    quantity: 2,
    price: 7500,
    relatedPart: true,
    isLabour: false,
    isMachine: false,
  },
  {
    id: 2,
    partNumber: "ST60MB",
    description: "Star 60 Main Board",
    quantity: 1,
    price: 26360,
    relatedPart: true,
    isLabour: false,
    isMachine: false,
  },
  {
    id: 3,
    partNumber: "Labour (In hours)",
    description: "",
    quantity: 5,
    price: 70,
    relatedPart: false,
    isLabour: true,
    isMachine: false,
  },
  {
    id: 4,
    partNumber: "Travel (In km)",
    description: "",
    quantity: 20,
    price: 50,
    relatedPart: false,
    isLabour: false,
    isMachine: true,
  },
];

const WarrantyClaimSummaryModal = ({
  show,
  hideModal,
  handleSnack,
  openFileUploadModal,
  handleOpenCloseFileUploadModal,
  recordData = {},
  partsRecord = [],
}) => {
  const [claimReqData, setClaimReqData] = useState({ ...claimRequestObj });
  const [claimOrderReqData, setClaimOrderReqData] = useState({ ...claimOrderRequestObj });
  const [evaluationReqData, setEvaluationReqData] = useState({ ...evaluationRequestObj });
  const [evaluationPartsReqData, setEvaluationPartsReqData] = useState({ ...evaluationPartsRequestObj });
  const [relayedHRReqData, setRelayedHRReqData] = useState({ ...claimRelatedHERequestObj });

  const [claimValueReqData, setClaimValueReqData] = useState({ ...claimValueRequestObj });

  const [tableRecords, setTableRecords] = useState(partsRecord.length === 0 ? [...dummyTableRecords] : [...partsRecord]);

  const [loading, setLoading] = useState(false);
  //   const [eveluation, seteveluation] = useState(second)

  const [recordObj, setRecordObj] = useState({
    claimNumber: Math.floor(Math.random() * 90000) + 10000,
    claiment: "",
    customerNumber: "1021034",
    customerName: "Schneider Electric",
    dataIssued: new Date(),
    payTerms: new Date(),
    paymentTermsNote: "",
    serialNumber: "V100147",
    model: "J3",
    make: "Bluestar",
    family: "Heating Element",
    usage: "",
    failureCode: "FA2390",
    partCausingFailure: "J4M500B1007",
    return: { label: "Yes", value: "YES" },
    complaint: "",
    cause: "",
    correction: "",
    ...recordData,
  });

  // change the input field value
  const handleChangeInputValue = (e) => {
    const { name, value } = e.target;
    setRecordObj({ ...recordObj, [name]: value });
  };

  // ! ---------- Table Records update start ------------------ ! //

  // update the field value
  const handleUpdateTableRow = (e, rowData, fieldName, id) => {
    const { value } = e.target;
    const updatedTableRecords = tableRecords.map((record) => (record.id === rowData?.id ? { ...record, [fieldName]: value } : record));
    setTableRecords(updatedTableRecords);
  };

  // delete the row
  const handleDeleteTableRow = (rowData) => {
    const updatedTableRecords = tableRecords.filter((record) => record.id !== rowData?.id);
    setTableRecords(updatedTableRecords);
  };

  // add new row
  const handleAddNewRow = () => {
    const _tableRecords = [...tableRecords];
    _tableRecords.push({
      id: tableRecords.length + 1,
      partNumber: "",
      description: "",
      quantity: 1,
      price: 0,
      // relatedPart: true,
      // isLabour: false,
      // isMachine: false,
    });
    setTableRecords(_tableRecords);
  };

  // ! ---------- Table Records update end ------------------ ! //

  // create-update claim
  const handleAddUpdateClaim = async (restData = {}) => {
    const _claimNotes = () => {
      const { complaint, cause, correction } = recordObj || {};

      let notes = "";

      if (complaint) {
        notes += `Complaint:- ${complaint}`;
        if (cause) {
          notes += `\nCause:- ${cause}`;
          if (correction) {
            notes += `\nCorrection:- ${correction}`;
          }
        } else if (correction) {
          notes += `\nCorrection:- ${correction}`;
        }
      } else if (cause) {
        notes += `Cause:- ${cause}`;
        if (correction) {
          notes += `\nCorrection:- ${correction}`;
        }
      } else if (correction) {
        notes += `Correction:- ${correction}`;
      }

      return notes;
    };
    const rObj = {
      ...claimReqData,
      claimNumber: claimReqData?.claimNumber || Math.floor(Math.random() * 90000) + 10000,
      modelNumber: recordObj?.model,
      serialNumber: recordObj?.serialNumber,
      make: recordObj?.make,
      family: recordObj?.family,
      claimStatus: "REGISTERED",
      claimType: "STANDARD",
      payer: "CUSTOMER",
      customerNumber: recordObj?.customerNumber,
      customerName: recordObj?.customerName,
      replacement: false,
      claimNotes: _claimNotes(),
      ...restData,

      //   assessmentId: 0,
      //   evaluationId: 0,
      //   claimOrderId: 0,
    };
    if (claimReqData?.claimId) {
      return await updateClaim(claimReqData?.claimId, rObj);
    } else {
      return await createClaim(rObj);
    }
  };

  // create-update claim order
  const handleAddUpdateClaimOrder = async (restData = {}) => {
    const rObj = {
      ...claimOrderReqData,
      customerNumber: recordObj?.customerNumber,
      customerName: recordObj?.customerName,
      model: recordObj?.model,
      serialNumber: recordObj?.serialNumber,
      make: recordObj?.make,
      family: recordObj?.family,
      claimType: "STANDARD",
      claimOrderStatus: "REGISTERED",
      claimOrderNumber: recordObj?.claimNumber,
      claiment: recordObj?.claiment,
      replacement: false,
      failureCode: recordObj?.failureCode,
      ...restData,
      //   relatedPartsIds: [],
      //   relatedHEId: 0,
      //   claimValueId: 0,
      //   claimId: 0,
    };
    if (claimOrderReqData?.claimOrderId) {
      return await updateClaimOrder(claimOrderReqData?.claimOrderId, rObj);
    } else {
      return await createClaimOrder(rObj);
    }
  };

  // create - update Evaluation
  const handleAddUpdateEvaluation = async (restData = {}) => {
    const rObj = {
      ...evaluationReqData,
      ...restData,
      //   claimId: "",
    };
    if (evaluationReqData?.evaluationId) {
      return await updateClaimEvaluation(evaluationReqData?.evaluationId, rObj);
    } else {
      return await createClaimEvaluation(rObj);
    }
  };

  // create Evaluation Parts
  const handleAddUpdateEvaluationParts = async (restData = {}) => {
    const rObj = {
      ...evaluationPartsReqData,
      partNumber: recordObj?.partCausingFailure,
      partDescription: "BRACKET",

      quantity: 1,
      ...restData,
      //   evaluationId: 0,
      //   partsHeaderId: 0,
    };
    if (evaluationPartsReqData?.partsId) {
      return await updateClaimEvaluationParts(evaluationPartsReqData?.partsId, rObj);
    } else {
      return await createClaimEvaluationPart(rObj);
    }
  };

  // create realted hours and expenses
  const handleAddUpdateRHandExpense = async (restData = {}) => {
    const _jobHours = tableRecords.find((item) => item?.isLabour);
    const _machineTravel = tableRecords.find((item) => item?.isMachine);
    const rObj = {
      ...relayedHRReqData,
      type: "OEM",
      code: "USER_DRIVED",
      coverageType: "CT_01",
      jobHours: _jobHours?.quantity,
      vehicleKM: _machineTravel?.quantity,

      ...restData,
      //   claimOrderId: 0,
    };

    if (relayedHRReqData?.relatedHEId) {
      return await updateRelatedHRandExpenses(relayedHRReqData?.relatedHEId, rObj);
    } else {
      return await createRelatedHRandExpenses(rObj);
    }
  };

  // create claim value
  const handleAddUpdateClaimValue = async (restData = {}) => {
    const _jobHours = tableRecords.find((item) => item?.isLabour);

    const _machineTravel = tableRecords.find((item) => item?.isMachine);

    // const _partsRecords = tableRecords.map((item) => item?.relatedPart);
    // const _totalPartsClaimed = _partsRecords.reduce((sum, item) => {
    //   return sum + item?.quantity * item?.price;
    // }, 0);

    const _tax =
      ((tableRecords.reduce((sum, item) => {
        return sum + (item?.relatedPart ? item?.quantity * item?.price : 0);
      }, 0) +
        (_jobHours?.quantity || 5) * (_jobHours?.price || 70) +
        (_machineTravel?.quantity || 20) * (_machineTravel?.price || 50)) *
        10) /
      100;

    const rObj = {
      ...claimValueReqData,
      coverageType: "CT_01",
      totalAmountClaimed:
        tableRecords.reduce((sum, item) => {
          return sum + (item?.relatedPart ? item?.quantity * item?.price : 0);
        }, 0) +
        (_jobHours?.quantity || 5) * (_jobHours?.price || 70) +
        (_machineTravel?.quantity || 20) * (_machineTravel?.price || 50) +
        _tax,
      totalPartsClaimed: tableRecords
        .reduce((sum, item) => {
          return sum + (item?.relatedPart ? item?.quantity * item?.price : 0);
        }, 0)
        .toFixed(2),
      totalHoursClaimed: _jobHours?.quantity || 5,
      totalLaborAmountClaimed: (_jobHours?.quantity || 5) * (_jobHours?.price || 70),
      travelClaimed: (_machineTravel?.quantity || 20) * (_machineTravel?.price || 50),
      vehicleKMClaimed: _machineTravel?.quantity || 20,
      tax: _tax,

      // jobHours: _jobHours?.quantity || 5,
      // vehicleKM: _machineTravel?.quantity || 20,

      ...restData,
      //   claimOrderId: 0,
    };

    if (claimValueReqData?.claimValueId) {
      return await updateClaimValue(claimValueReqData?.claimValueId, rObj);
    } else {
      return await createClaimValue(rObj);
    }
  };

  // claick on submit button
  const handleSubmit = async (e) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const _relatedPartsIds = [];
    if (tableRecords.length !== 0) {
      tableRecords.map((obj) => {
        if (obj.relatedPartsId) {
          _relatedPartsIds.push(obj.relatedPartsId);
        }
      });
    }
    let createPartsIds = [];
    await handleAddUpdateClaim({})
      .then(async (claimRes) => {
        if (claimRes) {
          setClaimReqData({ ...claimRes });
          await handleAddUpdateClaimOrder({ claimId: claimRes?.claimId })
            .then(async (claimOrderRes) => {
              if (claimOrderRes) {
                setClaimOrderReqData({ ...claimOrderRes });
                await handleAddUpdateEvaluation({ claimId: claimRes?.claimId })
                  .then(async (evaluationRes) => {
                    if (evaluationRes) {
                      setEvaluationReqData({ ...evaluationRes });
                      await handleAddUpdateEvaluationParts({ evaluationId: evaluationRes?.evaluationId })
                        .then(async (evaluationParts) => {
                          if (evaluationParts) {
                            const rowsData = [];
                            let currentDate = new Date();
                            let obj = {};
                            const _partsRecords = tableRecords.map((item) => item?.relatedPart);
                            for (const row of tableRecords) {
                              if (row?.relatedPart) {
                                obj = {
                                  segment: "",
                                  jobCode: "",
                                  title: "",
                                  compCode: "",
                                  description: row.description,
                                  portfolioId: "",
                                  subDescription: "",
                                  version: "",
                                  taskType: "",
                                  quantity: row?.quantity || 1,
                                  model: recordObj?.model,
                                  serialNo: row.partNumber,
                                  validFrom: currentDate,
                                  validTo: currentDate,
                                  unitPrice: row.price,
                                  extendedPrice: 0,
                                  discount: 0,
                                  totalPrice: row.price * row?.quantity,
                                  claimOrderId: claimOrderRes?.claimOrderId,
                                };
                                if (!row?.relatedPartsId) {
                                  const result = await createRelatedParts(obj);
                                  if (result) {
                                    setTableRecords(
                                      tableRecords.map((item) => (item?.id === row?.id ? { ...item, relatedPartsId: result?.relatedPartsId } : item))
                                    );
                                    if (!createPartsIds.includes(result?.relatedPartsId)) {
                                      createPartsIds.push(result?.relatedPartsId);
                                    }
                                  }
                                } else {
                                  const result = await updateRelatedParts(row?.relatedPartsId, obj);
                                  if (result) {
                                    if (!createPartsIds.includes(result?.relatedPartsId)) {
                                      createPartsIds.push(result?.relatedPartsId);
                                    }
                                  }
                                }
                              }
                            }
                            await handleAddUpdateRHandExpense({ claimOrderId: claimOrderRes?.claimOrderId })
                              .then(async (raltedHoursandExpenseRes) => {
                                if (raltedHoursandExpenseRes) {
                                  setRelayedHRReqData({ ...raltedHoursandExpenseRes });
                                  await handleAddUpdateClaimValue({ claimOrderId: claimOrderRes?.claimOrderId })
                                    .then(async (claimValueRes) => {
                                      if (claimValueRes) {
                                        setClaimValueReqData({ ...claimValueRes });
                                        await handleAddUpdateEvaluation({ claimId: claimRes?.claimId, evaluationPartIds: [evaluationParts?.partsId] })
                                          .then(async (evaluationUpdateRes) => {
                                            if (evaluationUpdateRes) {
                                              setEvaluationReqData({ ...evaluationUpdateRes });
                                              await handleAddUpdateClaimOrder({
                                                relatedPartsIds: [...createPartsIds],
                                                relatedHEId: raltedHoursandExpenseRes?.relatedHEId,
                                                claimValueId: claimValueRes?.claimValueId,
                                                claimId: claimRes?.claimId,
                                              })
                                                .then(async (updateClaimOrderRes) => {
                                                  if (updateClaimOrderRes) {
                                                    setClaimOrderReqData({ ...updateClaimOrderRes });
                                                    await handleAddUpdateClaim({
                                                      //   assessmentId: 0,
                                                      evaluationId: evaluationRes?.evaluationId,
                                                      claimOrderId: claimOrderRes?.claimOrderId,
                                                    })
                                                      .then(async (updateClaim) => {
                                                        if (updateClaim) {
                                                          setClaimReqData({ ...updateClaim });

                                                          handleSnack("success", `Claim ${recordObj?.claimNumber} is created successfully.`);
                                                          setLoading(false);
                                                          hideModal();
                                                        } else {
                                                          setLoading(false);
                                                          hideModal();
                                                        }
                                                      })
                                                      .catch((err) => {
                                                        setLoading(false);
                                                        hideModal();
                                                      });
                                                  } else {
                                                    setLoading(false);
                                                    hideModal();
                                                  }
                                                })
                                                .catch((err) => {
                                                  setLoading(false);
                                                  hideModal();
                                                });
                                            } else {
                                              setLoading(false);
                                              hideModal();
                                            }
                                          })
                                          .catch((err) => {
                                            setLoading(false);
                                            hideModal();
                                          });
                                      } else {
                                        setLoading(false);
                                        hideModal();
                                      }
                                    })
                                    .catch((err) => {
                                      setLoading(false);
                                      hideModal();
                                    });
                                } else {
                                  setLoading(false);
                                  hideModal();
                                }
                              })
                              .catch((err) => {
                                setLoading(false);
                                hideModal();
                              });
                          } else {
                            setLoading(false);
                            hideModal();
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          hideModal();
                        });
                    } else {
                      setLoading(false);
                      hideModal();
                    }
                  })
                  .catch((err) => {
                    setLoading(false);
                    hideModal();
                  });
              } else {
                setLoading(false);
                hideModal();
              }
            })
            .catch((err) => {
              setLoading(false);
              hideModal();
            });
        } else {
          setLoading(false);
          hideModal();
        }
      })
      .catch((err) => {
        setLoading(false);
        hideModal();
      });
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size={"xl"}>
        <Modal.Body>
          <h4>Warranty Claim Summary</h4>
          <Divider sx={{ mb: 2 }} />
          <div className={`${loading ? "create-parts-api-working" : ""}`} style={{ position: "relative" }}>
            {loading && (
              <div className="" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <Loader type="spinner-default" bgColor={"#872ff7"} color={"#FFFFFF"} size={40} />
              </div>
            )}
            <div className="row" style={{ opacity: loading ? "0.9" : "" }}>
              <div className="col-md-6 col-sm-6 border-right pe-3">
                <div className="row input-fields">
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group mb-0">
                      <label className="text-light-dark font-size-12 font-weight-500">ATTACHMENT</label>
                      <div className="card border px-3 py-1 cursor mb-0" onClick={handleOpenCloseFileUploadModal}>
                        <div className="d-flex">
                          <VerticalAlignTopOutlinedIcon sx={{ fontSize: "28px" }} />
                          <div className="mx-2">
                            <h5 className="mb-0 mt-0 font-size-13">Upload File</h5>
                            <span className="font-size-12">JPG, JPEG, PNG, less than 5MB</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">CLAIM NUMBER</label>
                      <input
                        type="text"
                        value={recordObj?.claimNumber}
                        placeholder="Claim Number"
                        name="claimNumber"
                        disabled
                        // onChange={handleCustomerDataChange}
                        className="form-control border-radius-10 text-primary"
                        id="claimNumber"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">YOUR COMPANY DETAILS</label>
                      <input
                        type="text"
                        value={recordObj?.claiment}
                        placeholder="Your Company Details"
                        name="claiment"
                        onChange={handleChangeInputValue}
                        className="form-control border-radius-10 text-primary"
                        id="claiment"
                      />
                      {/* <textarea
                      className="form-control border-radius-10 text-primary"
                      name="claiment"
                      cols="30"
                      rows="2"
                      // value={evaluationDetailsData.question5}
                      placeholder="Claiment..."
                    ></textarea> */}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NUMBER</label>
                      <input
                        type="text"
                        value={recordObj?.customerNumber}
                        placeholder="Customer Number"
                        name="customerNumber"
                        onChange={handleChangeInputValue}
                        className="form-control border-radius-10 text-primary"
                      />
                      {/* <textarea
                      className="form-control border-radius-10 text-primary"
                      name="customer"
                      cols="30"
                      rows="2"
                      // value={evaluationDetailsData.question5}
                      placeholder="Customer Details..."
                    ></textarea> */}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">CUSTOMER NAME</label>
                      <input
                        type="text"
                        value={recordObj?.customerName}
                        placeholder="Customer Name"
                        name="customerName"
                        onChange={handleChangeInputValue}
                        className="form-control border-radius-10 text-primary"
                      />
                      {/* <textarea
                      className="form-control border-radius-10 text-primary"
                      name="customer"
                      cols="30"
                      rows="2"
                      // value={evaluationDetailsData.question5}
                      placeholder="Customer Details..."
                    ></textarea> */}
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">DATE ISSUESD</label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            closeOnSelect
                            value={recordObj?.dataIssued}
                            onChange={(e) => setRecordObj({ ...recordObj, dataIssued: e })}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">PAY TERMS</label>
                      <div className="align-items-center date-box">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            inputFormat="dd/MM/yyyy"
                            className="form-controldate border-radius-10"
                            closeOnSelect
                            value={recordObj?.payTerms}
                            onChange={(e) => setRecordObj({ ...recordObj, payTerms: e })}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, style: FONT_STYLE }} />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-2 my-2 border-radius-10" style={{ backgroundColor: "#F2F2F2" }}>
                  <div className="d-flex align-items-center">
                    <span className="font-weight-500" style={{ width: "55%" }}>
                      Item
                    </span>
                    <span className="font-weight-500" style={{ width: "15%" }}>
                      Rate
                    </span>
                    <span className="font-weight-500" style={{ width: "10%" }}>
                      Qnt.
                    </span>
                    <span className="font-weight-500" style={{ width: "15%" }}>
                      Amount
                    </span>
                  </div>
                  {tableRecords.length !== 0 &&
                    tableRecords.map((row, i) => (
                      <div className="d-flex my-2" key={row?.id}>
                        <div className="pr-3" style={{ width: "55%" }}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control border-radius-10"
                              name="serialNumber"
                              placeholder="Part Number"
                              readOnly
                              // readOnly={row?.editable ? }
                              value={`${row?.partNumber} ${row?.description && "- " + row?.description}`}
                              // onChange={(e) => handleUpdateTableRow(e, row, "quantity", i)}
                            />
                          </div>
                        </div>
                        <div className="pr-3" style={{ width: "15%" }}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control border-radius-10 text-center"
                              name="rate"
                              placeholder="Rate"
                              value={currencyFormatter.format(row?.price)}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="pr-3" style={{ width: "10%" }}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control border-radius-10 text-center"
                              name="serialNumber"
                              placeholder="Qnt."
                              value={row?.quantity}
                              // readOnly
                              onChange={(e) => handleUpdateTableRow(e, row, "quantity", i)}
                            />
                          </div>
                        </div>
                        <div className="pr-3" style={{ width: "15%" }}>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control border-radius-10"
                              name="serialNumber"
                              placeholder="Price"
                              value={currencyFormatter.format(row?.price * row?.quantity || 0)}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="pr-3 py-2" style={{ width: "5%" }}>
                          <span className="font-size-18 font-weight-500 cursor" onClick={() => handleDeleteTableRow(row)}>
                            X
                          </span>
                        </div>
                      </div>
                    ))}
                  <div className="d-flex justify-content-center">
                    <Tooltip title="Add More">
                      <button
                        className="btn bg-primary text-white d-flex justify-content-center align-items-center font-size-28 border-radius-50"
                        style={{ width: "50px", height: "50px" }}
                        onClick={handleAddNewRow}
                      >
                        +
                      </button>
                    </Tooltip>
                  </div>
                </div>
                <div className="row input-fields mt-3">
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">NOTES / PAYMENT TERMS</label>
                      <textarea
                        className="form-control border-radius-10 text-primary"
                        name="paymentTermsNote"
                        cols="30"
                        rows="2"
                        value={recordObj?.paymentTermsNote}
                        onChange={handleChangeInputValue}
                        placeholder="Payment Terms Note"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="row mb-2 align-items-center">
                      <div className="col-md-6 col-sm-6 text-right">
                        <h5 className="font-size-14">Subtotal</h5>
                      </div>
                      <div className="col-md-6 col-sm-6 text-right">
                        <h5 className="font-size-14">
                          {currencyFormatter.format(
                            tableRecords.reduce((sum, item) => {
                              return sum + item?.quantity * item?.price;
                            }, 0)
                          )}
                        </h5>
                      </div>
                    </div>
                    <div className="row mb-2 align-items-center">
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="font-size-13 text-right">Tax</p>
                      </div>
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="font-size-13 font-weight-400 border border-radius-10 p-1">
                          {currencyFormatter.format(
                            (tableRecords.reduce((sum, item) => {
                              return sum + item?.quantity * item?.price;
                            }, 0) *
                              10) /
                              100
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row mb-2 align-items-center">
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="font-size-13 text-right">Discount</p>
                      </div>
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="font-size-13 font-weight-400 border border-radius-10 p-1">-{currencyFormatter.format(0)}</p>
                      </div>
                    </div>
                    <div className="row mb-2 align-items-center">
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="font-size-13 text-right">Shipping Free</p>
                      </div>
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="font-size-13 font-weight-400 border border-radius-10 p-1">{currencyFormatter.format(0)}</p>
                      </div>
                    </div>
                    <div className="row mt-1 align-items-center">
                      <div className="col-md-6 col-sm-6 text-right">
                        <h5 className="font-size-14 text-primary">Total</h5>
                      </div>
                      <div className="col-md-6 col-sm-6 text-right">
                        <h5 className="font-size-14 text-primary">
                          {currencyFormatter.format(
                            tableRecords.reduce((sum, item) => {
                              return sum + item?.quantity * item?.price;
                            }, 0) +
                              (tableRecords.reduce((sum, item) => {
                                return sum + item?.quantity * item?.price;
                              }, 0) *
                                10) /
                                100
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 ps-3">
                <div className="row input-fields">
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">SERIAL NUMBER</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="serialNumber"
                        value={recordObj?.serialNumber}
                        placeholder="Serial Number"
                        onChange={handleChangeInputValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">MODEL</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="model"
                        value={recordObj?.model}
                        placeholder="Model Number"
                        onChange={handleChangeInputValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">MAKE</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="make"
                        value={recordObj?.make}
                        placeholder="Make"
                        onChange={handleChangeInputValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">FAMILY</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="family"
                        value={recordObj?.family}
                        placeholder="Family"
                        onChange={handleChangeInputValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">USAGE</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="usage"
                        value={recordObj?.usage}
                        placeholder="Usage"
                        onChange={handleChangeInputValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">FAILURE CODE</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="failureCode"
                        value={recordObj?.failureCode}
                        placeholder="Failure Code"
                        onChange={handleChangeInputValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">PART CAUSING FAILURE</label>
                      <input
                        type="text"
                        className="form-control border-radius-10 text-primary"
                        name="partCausingFailure"
                        value={recordObj?.partCausingFailure}
                        placeholder="Part Causeing Failur"
                        onChange={handleChangeInputValue}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">RETURN</label>
                      <Select
                        className="text-primary"
                        options={questionsOptions}
                        value={recordObj?.return}
                        onChange={(e) => setRecordObj({ ...recordObj, return: e })}
                        styles={FONT_STYLE_SELECT}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">COMPLAINT</label>
                      <textarea
                        className="form-control border-radius-10 text-primary"
                        name="complaint"
                        cols="20"
                        rows="2"
                        value={recordObj?.complaint}
                        onChange={handleChangeInputValue}
                        placeholder="Complaint..."
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">CAUSE</label>
                      <textarea
                        className="form-control border-radius-10 text-primary"
                        name="cause"
                        cols="20"
                        rows="2"
                        value={recordObj?.cause}
                        onChange={handleChangeInputValue}
                        placeholder="Cause..."
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group">
                      <label className="text-light-dark font-size-12 font-weight-500">CORRECTION</label>
                      <textarea
                        className="form-control border-radius-10 text-primary"
                        name="correction"
                        cols="20"
                        rows="2"
                        value={recordObj?.correction}
                        onChange={handleChangeInputValue}
                        placeholder="Correction..."
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <button className="btn border-primary text-primary w-100" disabled={loading} onClick={hideModal}>
                      Cancel
                    </button>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <button className="btn bg-primary text-white w-100" onClick={handleSubmit}>
                      {loading ? "Submitting" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {openFileUploadModal && <UploadFilesModal show={openFileUploadModal} hideModal={handleOpenCloseFileUploadModal} />}
    </>
  );
};

export default WarrantyClaimSummaryModal;
