import React, { useCallback, useEffect, useState } from "react";
import "./AIAssistence.css";

import AddIcon from "@mui/icons-material/Add";

import Loader from "react-js-loader";
import { FileUploader } from "react-drag-drop-files";
import { customerSearch, machineSearch } from "services/searchServices";
import { Box } from "@mui/material";

const defaultMessage = {
  sender: "bot",
  text: `Hello there!,<br />How can I help you today?`,
  prompt: ["Warranty", "Quote", "Inventory", "Kit"],
  //   promopat: ["Warranty", "Quote", "Inventory", "Kit"],
  //   prompt: `Warranty\nQuote\nInventory\nKit`,
  productList: [],
  showList: false,
  activeStep: 1,
  timestamp: new Date().toLocaleTimeString(),
  contentUI: "",
};

const defaultQuestions = [
  { question: "Enter the Customer Number", answer: "", index: 0 },
  { question: "Select any Serial Number or enter manualy", answer: "", index: 1 },
  { question: "Enter the Model Number", answer: "", index: 2 },
  { question: "Enter the Failure code", answer: "", index: 3 },
  { question: "Enter the Defective part", answer: "", index: 4 },
  { question: "Enter the Causal part", answer: "", index: 5 },
  { question: "Enter the Parts needed", answer: "", index: 6 },
  { question: "Enter the Quantity", answer: "", index: 7 },
  { question: "Do you want to enter another part?", answer: "", index: 8 },
  { question: "Enter the Hours spent", answer: "", index: 9 },
  { question: "Enter the Stipulated hours and rates", answer: "", index: 10 },
  { question: "Enter the Additional hours", answer: "", index: 11 },
  { question: "Enter the Travel Km", answer: "", index: 12 },
  { question: "Enter the Fixed cost for travel", answer: "", index: 13 },
  { question: "Enter the Vehicle cost", answer: "", index: 14 },
];

const AIAssistence = ({
  closeModal,
  handleShowGenerativeAIModal,
  handleShowWarrantySummaryModal,
  setAiRecordObj,
  aiPartsRecord = [],
  setAiPartsRecord,
}) => {
  const [questionsRecord, setQuestionsRecord] = useState(defaultQuestions);

  const [customerData, setCustomerData] = useState({
    customerNumber: "",
    customerName: "",
  });
  const [machineData, setMachineData] = useState({
    make: "",
    family: "",
  });

  const [questionIndex, setQuestionIndex] = useState(0);

  const [messages, setMessages] = useState([defaultMessage]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [startQuestions, setStartQuestions] = useState(false);

  const [activeStep, setActiveStep] = useState(1);

  // update the chat bot messages
  const updateChatbotMessage = useCallback(
    (updateMessage) => {
      setTimeout(() => {
        setMessages([...updateMessage]);
        setIsLoading(false);
        // Scroll to the bottom of the page smoothly

        // Get the first element with the class name 'my-class'
        const targetElement = document.getElementsByClassName("chat-window")[0];

        // Scroll to the element smoothly
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
          // targetElement.scrollTop = targetElement.scrollHeight;

          // Find the input field and focus on it
          const inputElement = document.getElementsByClassName(".userInput")[0];
          if (inputElement) {
            inputElement.focus();
          }
        }
        // window.scrollTo({
        //   top: document.body.scrollHeight,
        //   behavior: "smooth",
        // });
      }, 1500);
    },
    [activeStep]
  );

  // type message
  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  // send message
  const handleSendMessage = async () => {
    if (isLoading) return;
    if (userInput.trim() === "") return;
    if (startQuestions) {
      if (questionIndex === 1) {
        const timestamp = new Date().toLocaleTimeString();
        const newMessages = [
          ...messages,
          {
            sender: "user",
            text: userInput,
            timestamp: timestamp,
            prompt: [],
            productList: [],
            showList: false,
            activeStep: 1,
            contentUI: "",
          },
        ];
        let obj = {};
        setMessages(newMessages);
        await customerSearch("customerId:" + userInput).then(async (response) => {
          if (response && response.length > 0) {
            setCustomerData({
              customerNumber: response[0]?.customerId,
              customerName: response[0]?.fullName,
            });
            setMachineData({
              make: "",
              family: "",
            });
            await machineSearch(`currentClient:${userInput}&pageNumber=0&pageSize=20&sortColumn=id&orderBY=ASC`).then(async (equipmentResponse) => {
              setUserInput("");
              if (equipmentResponse && equipmentResponse.length > 0) {
                obj = {
                  sender: "bot",
                  text: "Select any Serial Number or type manually",
                  prompt: [],
                  productList: [...equipmentResponse],
                  showList: questionIndex === 1 ? true : false,
                  activeStep: 4,
                  timestamp: timestamp,
                  contentUI: "",
                };
                setTimeout(() => {
                  setIsLoading(true);
                  newMessages.push(obj);
                  updateChatbotMessage(newMessages);
                }, 500);
              } else {
                obj = {
                  sender: "bot",
                  text: "Enter a Serial Number manually",
                  prompt: [],
                  productList: [],
                  showList: questionIndex === 1 ? true : false,
                  activeStep: 4,
                  timestamp: timestamp,
                  contentUI: "",
                };
                setTimeout(() => {
                  setIsLoading(true);
                  newMessages.push(obj);
                  updateChatbotMessage(newMessages);
                }, 500);
              }
            });
          } else {
            let obj = {
              sender: "bot",
              text: "Sorry, This Customer number is not exits! <br />Please enter another",
              prompt: [],
              productList: [],
              showList: questionIndex === 1 ? true : false,
              activeStep: 4,
              timestamp: timestamp,
              contentUI: "",
            };
            setUserInput("");
            setTimeout(() => {
              setIsLoading(true);
              newMessages.push(obj);
              updateChatbotMessage(newMessages);
            }, 500);
          }
        });
      } else if (questionIndex === 6 || questionIndex === 7 || questionIndex === 8) {
        let obj = {};
        let _aiPartsRecord = [...aiPartsRecord];
        if (questionIndex === 7) {
          _aiPartsRecord.map((item) =>
            item?.id === _aiPartsRecord.length
              ? { ...item, quantity: questionIndex === 7 ? Number(userInput) : Number(questionsRecord[7]?.answer) }
              : item
          );
        } else {
          if (userInput.toLowerCase() === "st60dcb") {
            _aiPartsRecord.push({
              id: aiPartsRecord.length + 1,
              partNumber: questionIndex === 6 ? userInput : questionsRecord[6]?.answer,
              description: "Star 60 DC - DC Board",
              quantity: questionIndex === 7 ? Number(userInput) : Number(questionsRecord[7]?.answer),
              price: 7500,
              relatedPart: true,
              isLabour: false,
              isMachine: false,
            });
          } else if (userInput.toLowerCase() === "st60mb") {
            _aiPartsRecord.push({
              id: aiPartsRecord.length + 1,
              partNumber: questionIndex === 6 ? userInput : questionsRecord[6]?.answer,
              description: "Star 60 Main Board",
              quantity: questionIndex === 7 ? Number(userInput) : Number(questionsRecord[7]?.answer),
              price: 26360,
              relatedPart: true,
              isLabour: false,
              isMachine: false,
            });
          } else {
            _aiPartsRecord.push({
              id: aiPartsRecord.length + 1,
              partNumber: questionIndex === 6 ? userInput : questionsRecord[6]?.answer,
              description: "test description",
              quantity: questionIndex === 7 ? Number(userInput) : Number(questionsRecord[7]?.answer),
              price: 980,
              relatedPart: true,
              isLabour: false,
              isMachine: false,
            });
          }
        }
        setQuestionIndex(questionIndex + 1);
        const filteredArray = _aiPartsRecord.filter((obj) => Object.keys(obj).length > 0 && obj.partNumber);
        setAiPartsRecord(filteredArray);
        // setAiPartsRecord((prev) => [...prev, obj]);

        const timestamp = new Date().toLocaleTimeString();

        // ! user message Start ! //
        const newMessages = [
          ...messages,
          {
            sender: "user",
            text: userInput,
            timestamp: timestamp,
            prompt: [],
            productList: [],
            showList: false,
            activeStep: 1,
            contentUI: "",
          },
        ];
        setMessages(newMessages);

        // ! user message End ! //

        // ! bot message start ! //
        const _questionsRecord = [...questionsRecord];
        _questionsRecord[questionIndex].answer = userInput;
        setQuestionsRecord(_questionsRecord);

        let _question = questionsRecord[questionIndex + 1];

        let botMessage = {
          sender: "bot",
          text: _question?.question,
          prompt: questionIndex === 7 ? ["Yes", "No"] : [],
          productList: [],
          showList: false,
          activeStep: 4,
          timestamp: timestamp,
          contentUI: "",
        };
        setUserInput("");
        setTimeout(() => {
          setIsLoading(true);
          newMessages.push(botMessage);
          updateChatbotMessage(newMessages);
        }, 500);

        // ! bot message End ! //

        // setQuestionIndex(questionIndex + 1);
      } else {
        const timestamp = new Date().toLocaleTimeString();
        const newMessages = [
          ...messages,
          {
            sender: "user",
            text: userInput,
            timestamp: timestamp,
            prompt: [],
            productList: [],
            showList: false,
            activeStep: 1,
            contentUI: "",
          },
        ];
        setMessages(newMessages);

        if (questionIndex === questionsRecord.length - 1) {
          let obj = {
            sender: "bot",
            text: `Please wait a sec, generating your claim`,
            prompt: [],
            productList: [],
            showList: false,
            activeStep: 4,
            timestamp: timestamp,
            contentUI: "",
          };
          setUserInput("");
          setTimeout(() => {
            setIsLoading(true);
            newMessages.push(obj);
            updateChatbotMessage(newMessages);
          }, 500);

          const _failureCode = questionsRecord.find((item) => item?.index === 3);
          const _partCausingFailure = questionsRecord.find((item) => item?.index === 4);

          setAiRecordObj({
            customerNumber: customerData?.customerNumber || "",
            customerName: customerData?.customerName || "",
            serialNumber: questionsRecord[1]?.answer,
            model: questionsRecord[2]?.answer,
            make: machineData?.make,
            family: machineData?.family,
            failureCode: _failureCode?.answer,
            partCausingFailure: _partCausingFailure?.answer,
          });

          const _aiPartsRecord = [...aiPartsRecord];
          _aiPartsRecord.push(
            {
              id: aiPartsRecord.length + 1,
              partNumber: "Labour (In hours)",
              description: "",
              quantity: Number(questionsRecord[9]?.answer) || 5,
              price: 70,
              relatedPart: false,
              isLabour: true,
              isMachine: false,
            },
            {
              id: aiPartsRecord.length + 2,
              partNumber: "Travel (In km)",
              description: "",
              quantity: Number(questionsRecord[12]?.answer) || 20,
              price: 50,
              relatedPart: false,
              isLabour: false,
              isMachine: true,
            }
          );
          setAiPartsRecord(_aiPartsRecord);

          setUserInput("");
          setTimeout(() => {
            closeModal();
            handleShowWarrantySummaryModal();
          }, 2000);
        } else {
          const _questionsRecord = [...questionsRecord];
          _questionsRecord[questionIndex].answer = userInput;
          setQuestionsRecord(_questionsRecord);

          let _question = questionsRecord[questionIndex + 1];

          let obj = {
            sender: "bot",
            text: _question?.question,
            prompt: [],
            productList: [],
            showList: false,
            activeStep: 4,
            timestamp: timestamp,
            contentUI: "",
          };
          setUserInput("");
          setTimeout(() => {
            setIsLoading(true);
            newMessages.push(obj);
            updateChatbotMessage(newMessages);
          }, 500);
        }

        if (questionIndex < questionsRecord.length) {
          setQuestionIndex(questionIndex + 1);
        }
      }
    } else {
      const timestamp = new Date().toLocaleTimeString();
      const lastActiveStep = messages[messages.length - 1];
      const newMessages = [
        ...messages,
        {
          sender: "user",
          text: userInput,
          prompt: "",
          productList: [],
          showList: false,
          activeStep: lastActiveStep?.activeStep + 1,
          timestamp: timestamp,
          contentUI: "",
        },
      ];
      setMessages(newMessages);
      setUserInput("");
    }
  };

  // Image|File upload Modal box show|hide
  const handleImageFileUpload = (e, value) => {
    const newMessages = [
      ...messages,
      {
        sender: "user",
        text: e.name,
        timestamp: new Date().toLocaleTimeString(),
        prompt: [],
        productList: [],
        showList: false,
        activeStep: 1,
        contentUI: "",
      },
    ];
    setMessages(newMessages);

    setTimeout(() => {
      //   setIsLoading(true);
      closeModal();
      handleShowWarrantySummaryModal();
    }, 4000);
  };

  // select file
  const handleFileSelect = (file) => {
    // You can handle the selected file here
  };

  // select question prompat
  const handleSelectPrompt = async (e, activeSteper) => {
    const { innerText } = e.target;
    let obj = {};
    const timestamp = new Date().toLocaleTimeString();
    setStartQuestions(true);

    if (activeSteper === 1) {
      if (innerText.toLowerCase() === "warranty") {
        const newMessages = [
          ...messages,
          {
            sender: "user",
            text: innerText.toLowerCase(),
            timestamp: timestamp,
            prompt: [],
            productList: [],
            showList: false,
            activeStep: 1,
            contentUI: "",
          },
        ];

        setMessages(newMessages);

        obj = {
          sender: "bot",
          text: `For Warranty, Please select the below process`,
          prompt: [
            "Create claim by uploading service reports",
            "Create a claim by inputting text",
            "Create a claim through question and answers process",
          ],
          productList: [],
          showList: false,
          activeStep: 2,
          timestamp: timestamp,
        };
        setTimeout(() => {
          setIsLoading(true);
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
        }, 500);
      }
    } else if (activeSteper === 2) {
      let textmessage = innerText.toLowerCase();
      const newMessages = [
        ...messages,
        { sender: "user", text: innerText.toLowerCase(), timestamp: timestamp, prompt: [], productList: [], showList: false, activeStep: 1 },
      ];

      setMessages(newMessages);
      if (textmessage === "create claim by uploading service reports") {
        obj = {
          sender: "bot",
          text: `Drag and drop files to upload or Upload file`,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 3,
          timestamp: timestamp,
          contentUI: (
            <div className="card py-3 px-1" style={{ width: "100%" }}>
              <div className="justify-content-center">
                <h6 className="text-center font-weight-500 mt-3">
                  Drag and drop files to upload <br /> or
                </h6>
                <Box sx={{ minWidth: "100px", width: "100%" }}>
                  <FileUploader
                    name="file"
                    types={["JPG", "PNG"]}
                    sx={{ minWidth: "200px", maxWidth: "200px", width: "220px" }}
                    style={{ minWidth: "200px" }}
                    handleChange={handleImageFileUpload}
                    onSelect={handleFileSelect}
                  />
                </Box>
              </div>
            </div>
          ),
        };
        setTimeout(() => {
          setIsLoading(true);
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
        }, 500);
      } else if (textmessage === "create a claim by inputting text") {
        obj = {
          sender: "bot",
          text: `wait for a few seconds`,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 3,
          timestamp: timestamp,
          contentUI: "",
        };
        setTimeout(() => {
          setIsLoading(true);
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
        }, 200);

        setTimeout(() => {
          //   setIsLoading(true);
          closeModal();
          handleShowGenerativeAIModal();
        }, 4000);
      } else if (textmessage === "create a claim through question and answers process") {
        setStartQuestions(true);
        setQuestionIndex(1);
        let _question = questionsRecord[0];
        obj = {
          sender: "bot",
          text: _question?.question,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 4,
          timestamp: timestamp,
          contentUI: "",
        };
        setTimeout(() => {
          setIsLoading(true);
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
        }, 500);
      }
    } else if (activeSteper === 4) {
      const newMessages = [
        ...messages,
        { sender: "user", text: innerText, timestamp: timestamp, prompt: [], productList: [], showList: false, activeStep: 1 },
      ];

      setMessages(newMessages);
      if (innerText.toLowerCase() === "yes") {
        let _question = questionsRecord[6];

        let obj = {
          sender: "bot",
          text: _question?.question,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 4,
          timestamp: timestamp,
          contentUI: "",
        };
        setUserInput("");
        setTimeout(() => {
          setIsLoading(true);
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
        }, 500);
        setQuestionIndex(6);
      } else if (innerText.toLowerCase() === "no") {
        let _question = questionsRecord[9];
        const _questionsRecord = [...questionsRecord];
        _questionsRecord[8].answer = innerText;
        setQuestionsRecord(_questionsRecord);

        let obj = {
          sender: "bot",
          text: _question?.question,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 4,
          timestamp: timestamp,
          contentUI: "",
        };
        setUserInput("");
        setTimeout(() => {
          setIsLoading(true);
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
        }, 500);
        setQuestionIndex(9);
      }
    }

    // setActiveStep(activeStep + 1);
  };

  // handle the chatbot summary
  const handleChatbotSummary = async (conversationHistory) => {
    setIsLoading(true);
    const userResponses = conversationHistory
      .filter((msg) => msg.sender === "user")
      .map((msg) => msg.text)
      .join("\n");
    const prompt = `Here are the user's responses:\n${userResponses}\nPlease summarize the warranty claim in a clear and concise format.`;
    try {
      const botMessage = "Hello";
      const timestamp = new Date().toLocaleTimeString();
      setMessages([...conversationHistory, { sender: "bot", text: botMessage, timestamp: timestamp }]);
    } catch (error) {
      console.error("Error fetching summary from OpenAI:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Enter key press
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  // select the Producct|Serial Number
  const handleProductSelect = async (product) => {
    try {
      const timestamp = new Date().toLocaleTimeString();
      const newMessages = [
        ...messages,
        {
          sender: "user",
          text: `${product?.makerSerialNumber}`,
          timestamp: timestamp,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 1,
          contentUI: "",
        },
      ];

      setMachineData({
        make: product?.market,
        family: product?.maker,
      });
      setMessages(newMessages);

      let _question = questionsRecord[3];
      const _questionsRecord = [...questionsRecord];
      _questionsRecord[1].answer = product?.makerSerialNumber;
      _questionsRecord[2].answer = product?.model;
      setQuestionsRecord(_questionsRecord);
      setQuestionIndex(3);

      let obj = {
        sender: "bot",
        text: `Model Number is ${product?.model ? product?.model : "null"} for serial number ${product?.makerSerialNumber}`,
        prompt: [],
        productList: [],
        showList: false,
        activeStep: 4,
        timestamp: timestamp,
        contentUI: "",
      };
      setIsLoading(true);
      setTimeout(async () => {
        newMessages.push(obj);
        await updateChatbotMessage(newMessages);

        obj = {
          sender: "bot",
          text: _question?.question,
          prompt: [],
          productList: [],
          showList: false,
          activeStep: 4,
          timestamp: timestamp,
          contentUI: "",
        };

        setIsLoading(true);
        setTimeout(() => {
          newMessages.push(obj);
          updateChatbotMessage(newMessages);
        }, 500);
      }, 500);
    } catch (error) {}
  };

  return (
    <div className="app-container">
      <div className="chat-header">
        <svg id="bot-avatar" data-name="Layer 1" className="bot-avatar" xmlns="http://www.w3.org/2000/svg" viewBox="2 2 125.88 135.35">
          {/* <title>chatbot</title> */}
          <path d="M57.49,29.2V23.53a14.41,14.41,0,0,1-2-.93A12.18,12.18,0,0,1,50.44,7.5a12.39,12.39,0,0,1,2.64-3.95A12.21,12.21,0,0,1,57,.92,12,12,0,0,1,61.66,0,12.14,12.14,0,0,1,72.88,7.5a12.14,12.14,0,0,1,0,9.27,12.08,12.08,0,0,1-2.64,3.94l-.06.06a12.74,12.74,0,0,1-2.36,1.83,11.26,11.26,0,0,1-2,.93V29.2H94.3a15.47,15.47,0,0,1,15.42,15.43v2.29H115a7.93,7.93,0,0,1,7.9,7.91V73.2A7.93,7.93,0,0,1,115,81.11h-5.25v2.07A15.48,15.48,0,0,1,94.3,98.61H55.23L31.81,118.72a2.58,2.58,0,0,1-3.65-.29,2.63,2.63,0,0,1-.63-1.85l1.25-18h-.21A15.45,15.45,0,0,1,13.16,83.18V81.11H7.91A7.93,7.93,0,0,1,0,73.2V54.83a7.93,7.93,0,0,1,7.9-7.91h5.26v-2.3A15.45,15.45,0,0,1,28.57,29.2H57.49ZM82.74,47.32a9.36,9.36,0,1,1-9.36,9.36,9.36,9.36,0,0,1,9.36-9.36Zm-42.58,0a9.36,9.36,0,1,1-9.36,9.36,9.36,9.36,0,0,1,9.36-9.36Zm6.38,31.36a2.28,2.28,0,0,1-.38-.38,2.18,2.18,0,0,1-.52-1.36,2.21,2.21,0,0,1,.46-1.39,2.4,2.4,0,0,1,.39-.39,3.22,3.22,0,0,1,3.88-.08A22.36,22.36,0,0,0,56,78.32a14.86,14.86,0,0,0,5.47,1A16.18,16.18,0,0,0,67,78.22,25.39,25.39,0,0,0,72.75,75a3.24,3.24,0,0,1,3.89.18,3,3,0,0,1,.37.41,2.22,2.22,0,0,1,.42,1.4,2.33,2.33,0,0,1-.58,1.35,2.29,2.29,0,0,1-.43.38,30.59,30.59,0,0,1-7.33,4,22.28,22.28,0,0,1-7.53,1.43A21.22,21.22,0,0,1,54,82.87a27.78,27.78,0,0,1-7.41-4.16l0,0ZM94.29,34.4H28.57A10.26,10.26,0,0,0,18.35,44.63V83.18A10.26,10.26,0,0,0,28.57,93.41h3.17a2.61,2.61,0,0,1,2.41,2.77l-1,14.58L52.45,94.15a2.56,2.56,0,0,1,1.83-.75h40a10.26,10.26,0,0,0,10.22-10.23V44.62A10.24,10.24,0,0,0,94.29,34.4Z" />
        </svg>
        {/* <img
          src="https://t4.ftcdn.net/jpg/04/46/38/69/360_F_446386956_DiOrdcxDFWKWFuzVUCugstxz0zOGMHnA.jpg"
          alt="bot-avatar"
          className="bot-avatar"
        /> */}
        <div className="chat-header-details">
          <h2 className="text-white">AI Assistant</h2>
          <span>24×7 Support Bot</span>
        </div>
      </div>

      <div className="chat-window">
        <div className="chat-messages">
          {messages.map((msg, index) => (
            <>
              <div key={index} className={`message ${msg?.sender === "user" ? "user-message" : "bot-message"}`}>
                <div
                  className="message-text"
                  dangerouslySetInnerHTML={{
                    __html: msg?.text,
                  }}
                ></div>
                <div className="message-meta">
                  <span className="timestamp">{msg.timestamp}</span>
                </div>
              </div>

              <div className="bot-message-prompt">
                {msg?.prompt.length > 0 &&
                  msg?.prompt.map((prompt, j) => (
                    <p key={`prompt-${j}`} className="py-2 px-2" onClick={(e) => handleSelectPrompt(e, msg?.activeStep)}>
                      {prompt}
                    </p>
                  ))}
              </div>

              {msg?.contentUI && <div className="bot-message-card">{msg?.contentUI}</div>}
              {msg?.showList && msg?.productList.length > 0 && (
                <div className="product-list">
                  {msg?.productList.map(
                    (product) =>
                      product?.makerSerialNumber && (
                        <button key={product.id} className="product-option" onClick={() => handleProductSelect(product)}>
                          {product.makerSerialNumber}
                        </button>
                      )
                  )}
                </div>
              )}
            </>
          ))}

          {isLoading && (
            <div className="bot-message-typing-indicator typing-indicator">
              <div className="d-flex align-items-center justify-content-start">
                <Loader type="bubble-top" bgColor={"#808080"} title={""} color={"#FFFFFF"} size={25} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          id="userInput"
          className="userInput"
          onKeyPress={handleKeyPress}
          autoComplete="off"
          placeholder="Ask anything ..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default AIAssistence;
