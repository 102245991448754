import React, { useState } from "react";

import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";

import { Divider } from "@mui/material";

import Loader from "react-js-loader";

import { Modal } from "react-bootstrap";
import { callPostApi } from "services/ApiCaller";
import { API_SUCCESS } from "services/ResponseCode";
import { createGenerativeAI } from "services/warrantyServices";
import { sparePartSearch } from "services/searchServices";

const PARTS_QUOTE = "partsQuote";
const REPAIR_QUOTE = "repairQuote";
const WARRANTY_CLAIM = "walletClaim";
const PARTS_ORDER = "partsOrder";
const PARTS_RETURN = "partsReturn";
const TIMESHEET = "timesheet";

const GenerativeAISupportModal = ({ show, hideModal, handleSnack, handleShowWarrantySummaryModal, setAiRecordObj, setAiPartsRecord }) => {
  const [searchValue, setSearchValue] = useState("");
  const [orderType, setOrderType] = useState("");

  const [loading, setLoading] = useState(false);

  // change the input value
  const handleChangeValue = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  // select the order type
  const handleSelectOrderType = (orderValue) => {
    if (!searchValue) {
      handleSnack("info", "Please enter the required details.");
      return;
    }
    setOrderType(orderValue);
  };

  // submit
  const handleSubmit = async () => {
    if (!searchValue) {
      handleSnack("info", "Please enter the required details.");
      return;
    } else if (!orderType) {
      handleSnack("info", "Please Select the request type.");
      return;
    }

    const rObj = { user_input: searchValue };

    const rUrl = `https://b617qxh7la.execute-api.us-east-2.amazonaws.com/dev/`;

    setLoading(true);

    await createGenerativeAI(rObj).then(async (res) => {
      if (res) {
        const result = res["extracted_fields"];
        const rowData = [];

        for (const row of result) {
          let obj = {};
          if (row["Part Number"]) {
            await sparePartSearch(`partNumber:${row["Part Number"]}`).then((equipmentRes) => {
              const partNumberIndex = result.findIndex((item) => item["Part Number"] === row["Part Number"]);
              if (equipmentRes && equipmentRes.length > 0) {
                obj = {
                  id: rowData?.length + 1,
                  partNumber: equipmentRes[0]?.partNumber,
                  description: equipmentRes[0]?.partDescription,
                  quantity: result[partNumberIndex + 1]["Quantity"],
                  price: equipmentRes[0]?.listPrice,
                  relatedPart: true,
                  isLabour: false,
                  isMachine: false,
                };
                rowData.push({
                  id: rowData?.length + 1,
                  partNumber: equipmentRes[0]?.partNumber,
                  description: equipmentRes[0]?.partDescription,
                  quantity: result[partNumberIndex + 1]["Quantity"],
                  price: equipmentRes[0]?.listPrice,
                  relatedPart: true,
                  isLabour: false,
                  isMachine: false,
                });
              } else {
                obj = {
                  id: rowData?.length + 1,
                  partNumber: row["Part Number"],
                  description: "test description",
                  quantity: result[partNumberIndex + 1]["Quantity"],
                  price: 100,
                  relatedPart: true,
                  isLabour: false,
                  isMachine: false,
                };
                rowData.push({
                  id: rowData?.length + 1,
                  partNumber: row["Part Number"],
                  description: "test description",
                  quantity: result[partNumberIndex + 1]["Quantity"],
                  price: 100,
                  relatedPart: true,
                  isLabour: false,
                  isMachine: false,
                });
              }
            });
          } else if (row["Labour Hours"]) {
            obj = {
              id: rowData.length + 1,
              partNumber: "Labour (In hours)",
              description: "",
              quantity: row["Labour Hours"],
              price: 70,
              relatedPart: false,
              isLabour: true,
              isMachine: false,
            };
            rowData.push({
              id: rowData.length + 1,
              partNumber: "Labour (In hours)",
              description: "",
              quantity: row["Labour Hours"],
              price: 70,
              relatedPart: false,
              isLabour: true,
              isMachine: false,
            });
          } else if (row["Vehicle KM"]) {
            obj = {
              id: rowData.length + 1,
              partNumber: "Travel (In km)",
              description: "",
              quantity: row["Vehicle KM"],
              price: 50,
              relatedPart: false,
              isLabour: false,
              isMachine: true,
            };
            rowData.push({
              id: rowData.length + 1,
              partNumber: "Travel (In km)",
              description: "",
              quantity: row["Vehicle KM"],
              price: 50,
              relatedPart: false,
              isLabour: false,
              isMachine: true,
            });
          }
        }

        const _customerName = result.find((item) => item["Customer Name"]);
        const _customerNumber = result.find((item) => item["Customer Number"]);
        const _serialNumber = result.find((item) => item["Serial Number"]);
        const _model = result.find((item) => item["Model"]);
        const _make = result.find((item) => item["Manufacturer"]);
        const _failureCode = result.find((item) => item["Failure Code"]);
        const _partCausingFailure = result.find((item) => item["To replace Part Number"]);

        const _family = result.find((item) => item["Family"]);

        const rObj = {
          customerNumber: (_customerNumber && _customerNumber["Customer Number"]) || "",
          customerName: (_customerName && _customerName["Customer Name"]) || "",
          serialNumber: (_serialNumber && _serialNumber["Serial Number"]) || "",
          model: (_model && _model["Model"]) || "",
          make: (_make && _make["Manufacturer"]) || "",
          failureCode: (_failureCode && _failureCode["Failure Code"]) || "",
          partCausingFailure: (_partCausingFailure && _partCausingFailure["To replace Part Number"]) || "",
          family: (_family && _family["Family"]) || "",
        };

        setAiRecordObj({
          customerNumber: (_customerNumber && _customerNumber["Customer Number"]) || "",
          customerName: (_customerName && _customerName["Customer Name"]) || "",
          serialNumber: (_serialNumber && _serialNumber["Serial Number"]) || "",
          model: (_model && _model["Model"]) || "",
          make: (_make && _make["Manufacturer"]) || "",
          failureCode: (_failureCode && _failureCode["Failure Code"]) || "",
          partCausingFailure: (_partCausingFailure && _partCausingFailure["To replace Part Number"]) || "",
          family: (_family && _family["Family"]) || "",
        });

        setAiPartsRecord(rowData);
        setLoading(false);
        handleShowWarrantySummaryModal();
      } else {
        handleSnack("error", "Something went wrong");
        setLoading(false);
      }
    });
  };

  return (
    <Modal show={show} onHide={hideModal} size={"lg"}>
      <Modal.Body style={{ backgroundColor: "#f4f4f4" }}>
        <h4>Generative AI</h4>
        <Divider sx={{ mb: 2 }} />
        <div className={`${loading ? "create-parts-api-working" : ""}`} style={{ position: "relative" }}>
          {loading && (
            <div
              className="loder-bg-blue-white-gradient"
              style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
            >
              <Loader
                type="spinner-default"
                bgColor={"#872ff7"}
                className="loder-bg-blue-white-gradient"
                color={"#000000"}
                title="Please Wait few seconds.."
                size={40}
              />
            </div>
          )}
          <div className="row" style={{ opacity: loading ? "0.9" : "" }}>
            <div className="col-md-12 col-sm-12 col-lg-12">
              <div className="card border p-3" style={{ backgroundColor: "#f2edf987" }}>
                <div className="row input-fields">
                  <div className="col-md-12 col-sm-12">
                    <div className="form-group">
                      <div className="customselectsearch">
                        <div className="form-control-ai-search">
                          <ConstructionOutlinedIcon className="text-primary" style={{ fontSize: 35 }} />
                        </div>
                        <div className="form-control-search-input">
                          <input
                            type="text"
                            className="form-control border-radius-10 text-primary"
                            value={searchValue}
                            autoComplete="off"
                            onChange={handleChangeValue}
                            placeholder={"Ask whatever you want or make a request..."}
                            style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center" style={{ justifyContent: "space-evenly" }}>
                  <div
                    className={`card border px-3 py-2 font-size-11 font-weight-500 cursor ${
                      orderType === PARTS_QUOTE ? "bg-primary text-white" : "order-for-parts-equ"
                    }`}
                    onClick={() => handleSelectOrderType(PARTS_QUOTE)}
                  >
                    Parts Quote
                  </div>
                  <div
                    className={`card border px-3 py-2 font-size-11 font-weight-500 cursor ${
                      orderType === REPAIR_QUOTE ? "bg-primary text-white" : "order-for-parts-equ"
                    }`}
                    onClick={() => handleSelectOrderType(REPAIR_QUOTE)}
                  >
                    Repair Quote
                  </div>
                  <div
                    className={`card border px-3 py-2 font-size-11 font-weight-500 cursor ${
                      orderType === WARRANTY_CLAIM ? "bg-primary text-white" : "order-for-parts-equ"
                    }`}
                    onClick={() => handleSelectOrderType(WARRANTY_CLAIM)}
                  >
                    Warranty Claim
                  </div>
                  <div
                    className={`card border px-3 py-2 font-size-11 font-weight-500 cursor ${
                      orderType === PARTS_ORDER ? "bg-primary text-white" : "order-for-parts-equ"
                    }`}
                    onClick={() => handleSelectOrderType(PARTS_ORDER)}
                  >
                    Parts Order
                  </div>
                  <div
                    className={`card border px-3 py-2 font-size-11 font-weight-500 cursor ${
                      orderType === PARTS_RETURN ? "bg-primary text-white" : "order-for-parts-equ"
                    }`}
                    onClick={() => handleSelectOrderType(PARTS_RETURN)}
                  >
                    Parts Return
                  </div>
                  <div
                    className={`card border px-3 py-2 font-size-11 font-weight-500 cursor ${
                      orderType === TIMESHEET ? "bg-primary text-white" : "order-for-parts-equ"
                    }`}
                    onClick={() => handleSelectOrderType(TIMESHEET)}
                  >
                    Timesheet
                  </div>
                </div>
              </div>
              <div className="row px-3" style={{ justifyContent: "right" }}>
                <button className="btn border-primary text-primary mx-2" onClick={hideModal} disabled={loading}>
                  Cancel
                </button>
                <button className="btn bg-primary text-white" onClick={handleSubmit} disabled={loading}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GenerativeAISupportModal;
